import React from 'react'

import MemberItem from './MemberItem'
import styles from './MemberList.module.css'

const MemberList = ({ members }) => (
  <ul className={styles.MemberList}>
    {members.map((member, i) => (
      <li key={i}>
        <MemberItem member={member} />
      </li>
    ))}
  </ul>
)

export default MemberList
