import Img from 'gatsby-image'
import React from 'react'

import markdown from '../utils/markdown'
import styles from './MemberItem.module.css'
import stylesText from './Text.module.css'

const MemberItem = ({ member }) => (
  <section className={styles.MemberItem}>
    <figure>
      <Img fluid={member.image.childImageSharp.fluid} />
    </figure>
    <h1>
      <span lang="ja">{member.nameJa}</span>
      <span lang="en">{member.nameEn}</span>
    </h1>
    <div className={styles.MemberItem_Profile}>
      <div className={stylesText.Text}>{markdown(member.profile)}</div>
    </div>
  </section>
)

export default MemberItem
