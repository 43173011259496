import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import React from 'react'

import DocumentContainer from '../components/DocumentContainer'
import Layout from '../components/Layout'
import markdown from '../utils/markdown'
import MemberList from '../components/MemberList'
import Section from '../components/Section'
import stylesCompany from '../components/Company.module.css'
import stylesConcept from '../components/Concept.module.css'
import stylesText from '../components/Text.module.css'

export default ({ data: { aboutYaml: node }, location }) => (
  <Layout location={location}>
    <Helmet title="About" />
    <DocumentContainer>
      <Section title="About">
        <div className={stylesConcept.Concept}>
          <div className={stylesText.Text} lang="ja">
            {markdown(node.aboutJa)}
          </div>
          <div className={stylesText.Text} lang="en">
            {markdown(node.aboutEn)}
          </div>
          <div>
            <MemberList members={node.members} />
          </div>
        </div>
      </Section>
      <Section title="Company">
        <div className={stylesCompany.Company}>
          <div
            className={`${stylesText.Text} ${stylesText.isFontSizeBody} ${stylesText.isLineHeightTight}`}
          >
            {markdown(node.company)}
          </div>
        </div>
      </Section>
      <Section title="Awards">
        <div
          className={`${stylesText.Text} ${stylesText.isFontSizeBody} ${stylesText.isLineHeightTight}`}
        >
          {markdown(node.awards)}
        </div>
      </Section>
    </DocumentContainer>
  </Layout>
)

export const query = graphql`
  {
    aboutYaml {
      aboutJa
      aboutEn
      company
      members {
        nameJa
        nameEn
        profile
        image {
          childImageSharp {
            fluid(maxWidth: 502, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      awards
    }
  }
`
